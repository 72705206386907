import React from 'react';

import { NamedLink, NamedRedirect, PrimaryButton } from '../../components';
import css from './EditListingDoneForm.module.css';
import coverImage from '../../assets/images/cover.png';
import { createResourceLocatorString } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Done = props => {
  const { id, slug } = props?.params;
  const history = useHistory();
  const routes = useRouteConfiguration();

  return (
    <div className={css.completeBoxOuter}>
      <div className={css.completeBox}>
        <img src={coverImage} />
        <div className={css.mainHeading}>
          <h1 className={css.headingText}>You're Done!</h1>
          <p className={css.detailText}>
          Matched installers will send you quotes based on the information you shared. The more detailed the information, the more accurate the quote! You can view quotes by checking your email, or your dashboard.
          </p>

          <div className={css.listInput}>
            <p className={css.detailText}>Sit back and receive your generator installation quotes.</p>
            <p className={css.detailText}>Review and approve incoming installer quotes.</p>
            <p className={css.detailText}>Finalize your install directly with the winning installer you chose.</p>
          </div>
          <div className={css.doneButton}>
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              onClick={() =>
                history.push(
                  createResourceLocatorString('LandingPage', routes, {
                    id,
                    slug,
                  })
                )
              }
            >
              Sound's Great
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Done;
