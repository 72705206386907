import React, { Component } from 'react';

import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Configure,
  MenuSelect,
  InfiniteHits,
  RefinementList,
  ClearRefinements,
} from 'react-instantsearch-dom';
import { GeoSearch, CustomMarker, GoogleMapsLoader } from 'react-instantsearch-dom-maps';

import { bool, func, oneOf, object, shape, string, number } from 'prop-types';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { intlShape, useIntl } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { orderBy } from 'lodash';
import { withViewport } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import {
  Page,
  ListingCard,
  IconMapMarker,
  Modal,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import {
  createSearchResultSchema,
  searchParamsPicker,
} from './AlgoliaSearchPage.helpers';

import css from './AlgoliaSearchPage.module.css';
import { brandOptionsIRF, budgetOptions, fuelSourceOptions, generatorTypes, planOptions, reasonOptions, soonOptions, squareFootage } from '../../util/constants';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY,
  {
    _useRequestCache: true,
  }
);

const limitsPerFacet = 30;
const updateAfter = 700;
const searchStateToUrl = searchState =>
  searchState ? `${window.location.pathname}?${qs.stringify(searchState)}` : '';

export class AlgoliaSearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      showMap: false,
      rangeValue: [40],
      searchState: typeof window !== 'undefined' && qs.parse(window.location.search.slice(1)),
      toggle: false,
      isSecondaryFiltersOpen: false,
    };

    this.searchMapListingsInProgress = false;
    this.handleToggleState = this.handleToggleState.bind(this);
    this.onSearchStateChange = this.onSearchStateChange.bind(this);
    typeof window !== 'undefined' &&
      window.addEventListener('popstate', ({ state: searchState }) => {
        this.setState({ searchState });
      });
  }

  handleToggleState = () => {
    this.setState({ toggle: true });
  };

  onSearchStateChange = searchState => {
    // update the URL when there is a new search state.
    clearTimeout(this.debouncedSetState);
    this.debouncedSetState = setTimeout(() => {
      typeof window !== 'undefined' &&
        window.history.pushState(searchState, null, searchStateToUrl(searchState));
    }, updateAfter);

    this.setState(previousState => {
      const hasQueryChanged = previousState.searchState.query !== searchState.query;

      return {
        ...previousState,
        searchState: {
          ...searchState,
          boundingBox: !hasQueryChanged ? searchState.boundingBox : null,
        },
      };
    });
  };

  render() {
    const {
      listings,
      scrollingDisabled,
      intl,
      config,
      params,
      pageName,
      history,
      location,
      searchParams,
      routeConfiguration,
      currentUser,
      currentUserListing,
      currentUserListingFetched,
      categories,
      subCategories,
      showList,
      subChildCategories,
      onManageDisableScrolling,
      // onAddOrRemoveToConnected,
    } = this.props;

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    // eslint-disable-next-line no-unused-vars
    // const { mapSearch, page, } = parse(location.search, {
    //   latlng: ['origin'],
    //   latlngBounds: ['bounds'],
    // });

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      false
    );

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

    const handleShowHide = () => {
      if (this.state.showMap) {
        this.setState({ showMap: false });
      } else {
        this.setState({ showMap: true });
      }
    };
    const toggleSecondaryFiltersOpen = () => {
      if (this.state.isSecondaryFiltersOpen) {
        this.setState({ isSecondaryFiltersOpen: false });
      } else {
        this.setState({ isSecondaryFiltersOpen: true });
      }
    };

    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const { searchState } = this.state;

    const parameters = {};
    if (!searchState.boundingBox) {
      parameters.aroundLatLngViaIP = true;
      parameters.aroundRadius = 'all';
      parameters.hitsPerPage = 8;
      parameters.maxValuesPerFacet = limitsPerFacet;
    }

    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
        className={css.mainWrapper}
      >
        <TopbarContainer
          hideSearchBar
          className={topbarClasses}
          currentPage="AlgoliaSearchPage"
          currentSearchParams={urlQueryParams}
        />
        <div className={css.container}>
          <div
            className={classNames(css.aisInstantSearch, currentUser ? '' : css.notLoginUserSearch)}
          >
            <InstantSearch
              searchClient={searchClient}
              indexName={process.env.REACT_APP_ALGOLIA_LISTING_INDEX}
              searchState={searchState}
              onSearchStateChange={this.onSearchStateChange}
            >
              <Configure {...parameters} />
              <div
                className={classNames(css.leftPanel, this.state.showMap ? css.fullLeftPanel : '')}
              >
                <div className={css.searchFilter}>
                  <div className={css.keywordFilter}>
                    <SearchBox
                      translations={{
                        placeholder: 'Keyword  | Location',
                      }}
                    />
                  </div>
                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="brand"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: brandOptionsIRF.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="soon"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: soonOptions.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}
                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="reason"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: reasonOptions.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="footage"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: squareFootage.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="budget"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: budgetOptions.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="fuelSource"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: fuelSourceOptions.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="type"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: generatorTypes.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  {this.state.showMap ? (
                    <div className={css.dateSelect}>
                      <MenuSelect
                        attribute="plan"
                        limit={limitsPerFacet}
                        transformItems={(items) => items.map(item => ({
                          ...item,
                          label: planOptions.find(op => op.key === item.label)?.label
                        }))}
                      />
                    </div>
                  ) : null}

                  <div
                    className={css.filterBox}
                    onClick={() => {
                      toggleSecondaryFiltersOpen();
                    }}
                  >
                    <IconMapMarker type="filter" />
                  </div>
                  <div className={css.mapToogleButton}>
                    <div className={css.mapLabel}>
                      {this.state.showMap ? 'Show Map' : 'Hide Map'}
                    </div>
                    <div className={css.checkboxWrapper} onClick={handleShowHide}>
                      <div
                        className={classNames(css.ball, {
                          [css.toggled]: !this.state.showMap,
                        })}
                      >
                        {' '}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={css.selectCategoryData}>
                  <RefinementList
                    limit={limitsPerFacet}
                    attribute="displayAddress"
                    transformItems={items => orderBy(items, ['label', 'count'], ['asc', 'desc'])}
                  />
                </div>

                {/*<div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="language.key" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="localHireTrade" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="price.amount" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="trades.inputValues.startedYear.label" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="trades.inputValues.level.label" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="insurance" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="user.gender" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div>
                <div className={css.selectCategoryData} style={{ display: 'none' }}>
                  <RefinementList attribute="availabilityPlan.entries.dayOfWeek" transformItems={(items) => {
                    return orderBy(items, ['label', 'count'], ['asc', 'desc']);
                  }} />
                </div> */}

                <ClearRefinements className={css.filterClearButton} />
                <div className={css.dividerLine} />
                <div
                  className={classNames(css.cardsGrid, this.state.showMap ? css.fullCardGrid : '')}
                >
                  <InfiniteHits
                    hitComponent={props => (
                      <ListingCard
                        {...props}
                        currentUser={currentUser}
                        categories={categories}
                        subCategories={subCategories}
                      />
                    )}
                  />
                </div>
              </div>
              {this.state.showMap || isMobileLayout ? null : (
                <div className={css.rightPanel}>
                  <div className={css.searchMapInput}>
                    {/* <AlgoliaAutoComplete
                      insights={true}
                      openOnFocus={true}
                      defaultActiveItemId={0}
                      getSources={({ query }) => [
                        {
                          sourceId: 'location.address',
                          getItems() {
                            return getAlgoliaResults({
                              searchClient,
                              queries: [
                                {
                                  indexName: 'TheProjectGarageDev',
                                  query,
                                },
                              ],
                            });
                          },
                          templates: {
                            item({ item, components }) {
                              // return <span
                              //   onClick={() => redirectTo(item.location.address)}
                              //   className="aa-ItemLink">
                              //   {item.location.address}
                              // </span>

                              return <AlgoliaAutoItem hit={item} redirectTo={redirectTo} components={components} />

                            },
                          },
                          getItemInputValue({ item }) {
                            return item.location.address;
                          },
                        },
                      ]}
                    /> */}
                  </div>
                  <div className={css.mapRightBar}>
                    <GoogleMapsLoader apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}>
                      {google => (
                        <GeoSearch google={google} enableRefineOnMapMove={false}>
                          {({ hits }) =>
                            hits.map(hit => (
                              <CustomMarker key={hit.objectID} hit={hit}>
                                <span style={{ backgroundColor: '#FFF', fontSize: '1rem' }}>
                                  <IconMapMarker type="markerone" />
                                </span>
                              </CustomMarker>
                            ))
                          }
                        </GeoSearch>
                      )}
                    </GoogleMapsLoader>
                  </div>
                </div>
              )}
              <Modal
                id="AlgoliaSearchPage.MenuList"
                isOpen={this.state.isSecondaryFiltersOpen}
                onClose={() => this.setState({ isSecondaryFiltersOpen: false })}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                <div className={css.filtersList}>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Preferred Generator Brand</div>

                    <MenuSelect
                      attribute="brand"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: brandOptionsIRF.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Install Availability</div>
                    <MenuSelect
                      attribute="soon"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: soonOptions.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Generator Needs</div>
                    <MenuSelect
                      attribute="reason"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: reasonOptions.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>
                      Homeowner House Square Footage
                    </div>
                    <MenuSelect
                      attribute="footage"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: squareFootage.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Budget</div>
                    <MenuSelect
                      attribute="budget"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: budgetOptions.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Fuel Source</div>
                    <MenuSelect
                      attribute="fuelSource"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: fuelSourceOptions.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Cooling Type</div>
                    <MenuSelect
                      attribute="type"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: generatorTypes.find(op => op.key === item.label)?.label
                      }))}
                    />
                  </div>
                  <div className={css.filterBox}>
                    <div className={css.labelName}>Homeowner Preferred Payment Method</div>
                    <MenuSelect
                      attribute="plan"
                      limit={limitsPerFacet}
                      transformItems={(items) => items.map(item => ({
                        ...item,
                        label: planOptions.find(op => op.key === item.label)?.label
                      }))}
                    />

                  </div>
                </div>

                <div className={css.bottomButton}>
                  <ClearRefinements className={css.clearLink} />
                </div>
              </Modal>
            </InstantSearch>
          </div>
        </div>
      </Page>
    );
  }
}

AlgoliaSearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  // filterConfig: config.custom.filters,
  // sortConfig: config.custom.sortConfig,
  activeListingId: null,
  initialSearchFormValues: {},
};

AlgoliaSearchPageComponent.propTypes = {
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  initialSearchFormValues: object,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const EnhancedAlgoliaSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <AlgoliaSearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
  } = state.user;

  return {
    currentUser,
    currentUserListing,
    currentUserListingFetched,
    categories,
    subCategories,
    subChildCategories,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AlgoliaSearchPage = compose(
  withViewport,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedAlgoliaSearchPage);

export default AlgoliaSearchPage;
